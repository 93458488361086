<template>
  <UiActionBar v-model="modelValue" :title="title" secondary-button-text="Cancel" @cancel="emits('cancel')">
    <div class="flex flex-row items-center gap-4">
      <UiTooltip name="assign" position-top>
        <template #activator>
          <button
            id="assign_agent"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            :disabled="isThereAnySelectedOrFailed"
            :class="{ '!cursor-default !text-black-40': isThereAnySelectedOrFailed }"
            @click="showAssignAgentPopup"
          >
            <UiIcon name="user"></UiIcon>
          </button>
        </template>
        <template #content>
          {{ isThereAnySelectedOrFailed ? 'Some failed leads are selected' : ' Assign user to the selected leads' }}
        </template>
      </UiTooltip>
      <UiTooltip v-if="showSendToQueueAction" name="send-queue" position-top>
        <template #activator>
          <button
            id="create_shadow_lead"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            :disabled="isThereAnySelectedOrFailed"
            :class="{ '!cursor-default !text-black-40': isThereAnySelectedOrFailed }"
            @click="showSendToQueuePopup"
          >
            <UiIcon name="user-group"></UiIcon>
          </button>
        </template>
        <template #content>{{
          isThereAnySelectedOrFailed ? 'Some failed leads are selected' : 'Send selected leads to the queue.'
        }}</template>
      </UiTooltip>
    </div>
  </UiActionBar>
</template>

<script setup lang="ts">
import { POPUPS } from '~/components/dynamic/maps'
import { STEPS, PERMISSIONS } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { InputItem, Lead } from '~/types'

const modelValue = defineModel<boolean>()

const emits = defineEmits(['input', 'cancel'])

const uiStore = useUiStore()

const title = computed(() => `${props.selectedIndexes.filter((s) => s).length} leads selected`)

const isThereAnySelectedOrFailed = computed(() =>
  props.leads.some((l, i) => props.selectedIndexes[i] && l.step?.code === STEPS.FAILED)
)

const showSendToQueueAction = computed(() => useHasPermissions([PERMISSIONS.CAN_SEND_LEAD_TO_QUEUE]))

type Props = {
  leads: Lead[]
  selectedIndexes: boolean[]
}
const props = defineProps<Props>()

const showAssignAgentPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
    {
      selectedLeads: props.leads.filter((_, i) => props.selectedIndexes[i]),
    },
    {
      input: (user: InputItem) => {
        uiStore.showSnackBanner(`Leads were successfully assigned and moved to ${user?.text} Pipeline.`)
        emits('input')
        modelValue.value = false
      },
    }
  )
}

const showSendToQueuePopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_SEND_TO_QUEUE,
    {
      selectedLeads: props.leads.filter((_, i) => props.selectedIndexes[i]),
    },
    {
      input: () => {
        emits('input')
        modelValue.value = false
      },
    }
  )
}
</script>

<style scoped></style>
